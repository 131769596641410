// eslint-disable-next-line import/prefer-default-export
export const paymentHero = {
  title: "Payment processing",
  subtext:
    "Whether you’re selling in-person, online, or on-the-go, SpotOn makes it easy to accept payments quickly and securely.",
  heroImg: "payment-hero.png",
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/products/payments/demo",
  },
  fatTitle: "Payments",
};

export const paymentTrusted = [
  {
    title: "300 million",
    subtext: "Marketing campaigns",
  },
  {
    title: "5+ million",
    subtext: "Loyalty redemptions",
  },
  {
    title: "2,000+",
    subtext: "New businesses each month",
  },
  {
    title: "7+ million",
    subtext: "Consumers",
  },
];

export const paymentWhiteGlove = {
  mainTitle: "We are there for you & your customers",
  title: "Powerful tools backed by personal service",
  content:
    "You’ve got a business to run, so you don’t have time to figure out complicated payment technology. That’s why we work with you to select the right payment solution for your business, and show you and your staff how to get the most from it. And we’ll continue to be there for you whenever you need help with 24/7 lifetime support.",
  ctaInfoPrimary: {
    ctaTitle: "Learn more",
    ctaTarget: "/products/payments/demo",
  },
};

export const paymentLargeFeatures = {
  sectionTitle: "The smartest way to take payments",
  featureBlocks: [
    {
      blockTitle: "Fair pricing. No junk fees",
      blockSubTitle:
        "You deserve to know exactly how much you’re paying for payment processing, and you shouldn’t have to worry about your rates going up while locked into a multi-year contract. Our pricing is transparent and affordable, and our agreement is month-to-month, so you can trust that we’ll continue to earn your business each and every day.",
      blockImg: "payment-a-new.png",
      blockList: [
        "Transparent pricing",
        "Next-day funding",
        "No hidden fees",
        "No long-term commitment",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/payments/demo",
      },
    },
    {
      blockTitle: "Point-of-sale and payment terminals",
      blockSubTitle:
        "No matter what type of business or restaurant you run, we have a payment solution that will help you work faster, keep your customers happy, and help you grow your customer base.",
      blockImg: "payment-b.png",
      blockList: [
        "Virtual terminal & invoicing",
        "SpotOn Mobile",
        "SpotOn Terminal",
        "SpotOn Restaurant",
        "Other point-of-sale options",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/payments/demo",
      },
    },
    {
      blockTitle: "Payments are just the beginning",
      blockSubTitle:
        "Payment processing is only a small part of what SpotOn can do to help you run and grow your business. Our payment solutions are designed to save you time while making it easier to stay connected with your customers, leading to increased sales.",
      blockImg: "payment-c.png",
      blockList: [
        "Market to your customers like a pro",
        "Improve your online reputation",
        "Get valuable customer data & insights",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/payments/demo",
      },
    },
  ],
};

export const paymentBusinessTypesCar = {
  title: "Built for businesses like yours",
  subtext:
    "No matter what type of business or restaurant you run, we’re here to help.",
  slides: [
    {
      bg: "restaurant.png",
      cardTitle: "Restaurants",
      cardContent: "Fine Dining, Casual Dining, QSR, Food Stores, Food Trucks",
      cardIcon: "cart.svg",
      icon: "restaurant.png",
      linkTo: "/restaurants",
    },
    {
      bg: "auto.png",
      cardTitle: "Automotive",
      cardContent:
        "Oil Change Shops, Auto Parts, Auto Body, Repair, Tire Shops, Tint Shops",
      icon: "automotive.png",
      linkTo: "/automotive",
    },
    {
      bg: "beauty.png",
      cardTitle: "Health & beauty",
      cardContent:
        "Nail/Hair Salons, Tanning Salons, Barbers, Dentists, Veterinarian",
      icon: "health-and-beauty.png",
      linkTo: "/health-and-beauty",
    },
    {
      bg: "professional.png",
      cardTitle: "Professional Services",
      cardContent:
        "Lawyer, Accountant, Tow Truck, Architect, Plumber, Appraiser",
      icon: "prof-services.png",
      linkTo: "/professional-services",
    },
  ],
};

export const paymentVideo = {
  title: "See SpotOn in action",
  videoBtn: "Watch video",
  posterImg: "payment-poster.png",
  videoUrlId: "3oWbgvaZcv0",
};

export const paymentSolutionsSection = {
  title: "More great tools to run your business",
  subText: `Save time and grow revenue with integrated SpotOn technology that makes sense for your business.`,
  SecondaryCta: {
    ctaTitle: "View all",
    ctaTarget: "/products/payments/demo",
  },
};

export const paymentTestimonials = {
  title: "What other business owners are saying",
  reviews: [
    {
      quote:
        "From the initial consultation, to ordering, to implementation and integration, the entire process of on-boarding the SpotOn POS System has been seamless and has exceeded our expectations.",
      reviewDate: "Mar 9, 2021",
      reviewer: "Pete M.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn continues to be an invaluable technology partner that listens well and proactively presents innovative solutions to drive our business forward.",
      reviewDate: "Feb 9, 2021",
      reviewer: "Ben P.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn has given me the data access I need to manage my business from a macro as well as micro perspective. The software is easy to access and utilize.",
      reviewDate: "Dec 5, 2020",
      reviewer: "Pamela H.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "Every issue and question has been answered or addressed within hours, if not minutes.I can't say enough good about this company and software.",
      reviewDate: "Feb 24, 2021",
      reviewer: "Brandy R.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "From back office, digital marketing, built our website to take orders off of. The programming is great to allow you to do more tasks with better trained staff.",
      reviewDate: "Dec 1, 2020",
      reviewer: "Marcus O.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
  ],
};

export const paymentIntegrations = {
  title: "Integrations",
  subtext:
    "Sync all kind of third party applications and service with SpotOn and automate your revenue growth, retention and marketing processes.",
  integrationsContent: [
    {
      title: "Compatible with SpotOn Register.",
      subtext:
        "Run and grow your business more efficiently than ever before with our cutting-edge point-of-sale solution.",
      contentImg: "register.png",
      ImgClassName: "register",
      btnInfo: {
        ctaTitle: "How it Works",
        ctaTarget: "/products/payments/demo",
      },
    },
    {
      title: "Out of the box Stripe integration.",
      subtext:
        "Accept payments and move money globally with Stripe’s powerful APIs and software solutions",
      contentImg: "phone.png",
      ImgClassName: "phone",
      btnInfo: {
        ctaTitle: "Read More",
        ctaTarget: "/products/payments/demo",
      },
    },
  ],
};

export const paymentCTA = {
  title: "Sign up for a free demo.",
  ctaImg: "cta-people.png",
  ctaInfo: {
    ctaTitle: "Learn more",
    ctaTarget: "/products/payments/demo",
  },
};

export const paymentFaqs = {
  title: "Frequently Asked Questions",
  ctaInfo: {
    ctaTitle: "View more",
    ctaTarget: "/products/payments/demo",
  },
  list: [
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
  ],
};

export const paymentsSolutionsSlides = [
  {
    title: "Appointments",
    img: "Appointments1.png",
    link: "/products/appointments",
  },
  {
    title: "Restaurants",
    link: "/restaurants",
    img: "Restaurant.png",
  },
  {
    title: "Loyalty",
    link: "/products/loyalty",
    img: "Loyalty.png",
  },
  {
    title: "Website",
    link: "/products/website",
    img: "E-commerce & websites.png",
  },
  {
    title: "Terminal",
    link: "/products/terminal",
    img: "Terminal.png",
  },
];
