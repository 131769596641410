import React from "react";
import { Row, Col, Typography } from "antd";
import Layout from "../components/Layout/layout";
import SEO from "../components/seo";
import SSI from "../components/SSI/SSI";
import { ssiSSI, ssiTrusted } from "../data/main-pages/ssi-data";
import SecondaryCta from "../components/Buttons/secondary-cta";
import TrustedNumbers from "../components/TrustedNumbers/TrustedNumbers";
import TestmonialReviews from "../components/TestimonialReviews/TestmonialReviews";
import { paymentTestimonials } from "../data/product-pages/payment-data";
import PartnerLogos from "../components/PartnerLogos/PartnerLogos";
import bg from "../images/hero-images/bg-shape.png";

const { Title } = Typography;

const SelfIdentification = () => {
  return (
    <Layout>
      <SEO title="SpotOn | SSI" />
      <Row
        align="middle"
        style={{
          padding: "64px 0px",
          backgroundImage: `url(${bg})`,
          backgroundSize: "cover",
        }}
      >
        <Col lg={24} xs={24} className="hero__content">
          <Title className="hero-b__title text-center mt-12">
            Find what’s best for your business
            <span className="blue_dot">.</span>
          </Title>
          <p className="hero-b__para text-center">
            There’s no one-size-fits-all solution. Tell us about your business.
          </p>
          <Row
            style={{ marginTop: "42px" }}
            className="hero__btns"
            align="middle"
            justify="center"
            wrap={false}
          >
            <SecondaryCta ctaTitle="Or contact us" target="/demo" />
          </Row>
        </Col>
      </Row>
      <SSI sectionData={ssiSSI} />
      <TrustedNumbers numbersArray={ssiTrusted} />
      <TestmonialReviews sectionData={paymentTestimonials} />
      <PartnerLogos />
    </Layout>
  );
};

export default SelfIdentification;
