import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';
import SsiForm from './ssiForm';
import { viewports } from '../../style-vars';

const { Title } = Typography;
const { mdOrSmaller, smOrSmaller } = viewports;

const SSI = (props) => {
  const { sectionData } = props;
  const { title, subtext, bgColor, ctaInfo } = sectionData;

  return (
    <div className="ssi" style={{ backgroundColor: `${bgColor}` }}>
      {title && (
        <>
          <Title level={2} className="text-center">
            {title}
            <span className="blue_dot">.</span>
          </Title>
          <p className="text-center">{subtext}</p>
        </>
      )}
      <SsiForm btnData={ctaInfo} />

      <style jsx global>{`
        .ssi {
          position: relative;
          padding: 48px;
          max-width: 85%;
          margin: auto;
          box-shadow: 0px 21.2459px 65.0656px rgba(1, 44, 109, 0.16);
          width: 100%;
          border-radius: 28px;
        }
        .ssi p {
          color: #445469;
          font-size: 16px;
          margin-top: 24px;
          margin-bottom: 48px;
        }
        @media ${mdOrSmaller} {
          .ssi {
            max-width: 80%;
            margin-top: 120px;
          }
        }
        @media ${smOrSmaller} {
          .ssi {
            padding: 44px 16px;
            max-width: 90%;
          }
        }
        .ssi .cta-primary {
          margin: auto;
          margin-right: auto !important;
          margin-top: 32px;
        }
        @media ${smOrSmaller} {
          .ssi .cta-primary {
            margin-top: 12px;
          }
        }
      `}</style>
    </div>
  );
};

SSI.propTypes = {
  sectionData: PropTypes.objectOf(PropTypes.any),
};
SSI.defaultProps = {
  sectionData: '',
};

export default SSI;
