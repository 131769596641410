// eslint-disable-next-line import/prefer-default-export
export const ssiSSI = {
  bgColor: '#fff',
  ctaInfo: {
    ctaTitle: 'See how we can help you',
  },
};

export const ssiTrusted = [
  {
    title: '7+',
    subtext: 'Million consumers',
  },
  {
    title: '5m+',
    subtext: 'Loyalty redemptions',
  },
  {
    title: '2,000+',
    subtext: 'New businesses each month',
  },
  {
    title: '24/7/365',
    subtext: 'Redemptions with SpotOn services and growing',
  },
];
